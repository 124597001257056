import type {SVGAttributes} from 'react';

const VisualIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.06445 5.17401C4.06445 4.30092 4.77223 3.59314 5.64533 3.59314H14.2272C15.1003 3.59314 15.8081 4.30092 15.8081 5.17401V6.52905C15.8081 7.40214 15.1003 8.10992 14.2272 8.10992H5.64533C4.77223 8.10992 4.06445 7.40214 4.06445 6.52905V5.17401ZM5.64533 4.94817C5.5206 4.94817 5.41949 5.04928 5.41949 5.17401V6.52905C5.41949 6.65377 5.5206 6.75488 5.64533 6.75488H14.2272C14.3519 6.75488 14.453 6.65377 14.453 6.52905V5.17401C14.453 5.04928 14.3519 4.94817 14.2272 4.94817H5.64533Z"
      fill="#4A4A4A"
    />
    <path
      d="M4.06445 10.5941C4.06445 9.72105 4.77223 9.01327 5.64533 9.01327H6.097C6.47119 9.01327 6.77452 9.31661 6.77452 9.69079C6.77452 10.065 6.47119 10.3683 6.097 10.3683H5.64533C5.5206 10.3683 5.41949 10.4694 5.41949 10.5941V11.0458C5.41949 11.42 5.11615 11.7233 4.74197 11.7233C4.36779 11.7233 4.06445 11.42 4.06445 11.0458V10.5941Z"
      fill="#4A4A4A"
    />
    <path
      d="M4.06445 13.7559C4.06445 14.629 4.77223 15.3368 5.64533 15.3368H6.097C6.47119 15.3368 6.77452 15.0334 6.77452 14.6592C6.77452 14.2851 6.47119 13.9817 6.097 13.9817H5.64533C5.5206 13.9817 5.41949 13.8806 5.41949 13.7559V13.3042C5.41949 12.93 5.11615 12.6267 4.74197 12.6267C4.36779 12.6267 4.06445 12.93 4.06445 13.3042V13.7559Z"
      fill="#4A4A4A"
    />
    <path
      d="M14.2272 9.01327C15.1003 9.01327 15.8081 9.72105 15.8081 10.5941V11.0458C15.8081 11.42 15.5047 11.7233 15.1306 11.7233C14.7564 11.7233 14.453 11.42 14.453 11.0458V10.5941C14.453 10.4694 14.3519 10.3683 14.2272 10.3683H13.7755C13.4013 10.3683 13.098 10.065 13.098 9.69079C13.098 9.31661 13.4013 9.01327 13.7755 9.01327H14.2272Z"
      fill="#4A4A4A"
    />
    <path
      d="M14.2272 15.3368C15.1003 15.3368 15.8081 14.629 15.8081 13.7559V13.3042C15.8081 12.93 15.5047 12.6267 15.1306 12.6267C14.7564 12.6267 14.453 12.93 14.453 13.3042V13.7559C14.453 13.8806 14.3519 13.9817 14.2272 13.9817H13.7755C13.4013 13.9817 13.098 14.2851 13.098 14.6592C13.098 15.0334 13.4013 15.3368 13.7755 15.3368H14.2272Z"
      fill="#4A4A4A"
    />
    <path
      d="M11.5171 9.69079C11.5171 10.065 11.2138 10.3683 10.8396 10.3683H9.03291C8.65873 10.3683 8.35539 10.065 8.35539 9.69079C8.35539 9.31661 8.65873 9.01327 9.03291 9.01327H10.8396C11.2138 9.01327 11.5171 9.31661 11.5171 9.69079Z"
      fill="#4A4A4A"
    />
    <path
      d="M10.8396 15.3368C11.2138 15.3368 11.5171 15.0334 11.5171 14.6592C11.5171 14.2851 11.2138 13.9817 10.8396 13.9817H9.03291C8.65873 13.9817 8.35539 14.2851 8.35539 14.6592C8.35539 15.0334 8.65873 15.3368 9.03291 15.3368H10.8396Z"
      fill="#4A4A4A"
    />
  </svg>
);

export default VisualIcon;
