import useLottieAnimation from '@/hooks/useLottieAnimation';

interface AnimatedImageProps {
  lottieData: any;
  className?: string;
  alt?: string;
}
export default function AnimatedImage({
  lottieData,
  className,
  alt,
}: AnimatedImageProps) {
  const animationRef = useLottieAnimation(lottieData, {loop: true});

  return (
    <div
      ref={animationRef}
      aria-label={alt}
      role="img"
      className={className}
    ></div>
  );
}
