import {useMemo} from 'react';

import {useMediaQuery} from '@/hooks/useMediaQuery';

export interface GmvSetting {
  coins: CoinSetting[];
  positions: LayoutSetting[];
}

interface LayoutSetting {
  coinIndex: number;
  rotation?: number;
  scale: number;
  path: string;
  start: number;
  blur?: number;
  zIndex?: number;
}

interface CoinSetting {
  src: string;
  size: {
    width: number;
    height: number;
  };
}

interface MovingCoinProps extends LayoutSetting {
  coin: CoinSetting;
  value: number;
  isMobile: boolean;
}

interface BackgroundCoinHolderProps extends GmvSetting {
  value: number;
}

function easeOutQuad(x: number): number {
  return 1 - (1 - x) * (1 - x);
}

const MovingCoin = ({
  coin,
  path,
  start,
  value,
  scale,
  isMobile,
  rotation = 0,
  blur = 0,
  zIndex = 1,
}: MovingCoinProps) => {
  const valueAdjusted = useMemo(() => {
    const valueNormalizedWithStart = Math.max(
      0,
      (value - start) / (100 - start),
    );

    // if mobile lets cap distance
    return Math.min(
      isMobile ? 75 : 100,
      easeOutQuad(valueNormalizedWithStart) * 100,
    );
  }, [start, isMobile, value]);

  return (
    <div
      className="coin absolute top-0 left-0"
      style={{
        offsetPath: path,
        transform: ` rotate(${rotation}deg) scale(${scale})`,
        filter: `blur(${blur}rem)`,
        width: coin.size.width,
        height: coin.size.height,
        offsetDistance: `${valueAdjusted}%`,
        zIndex: zIndex,
      }}
    >
      <img src={coin.src} alt="Subscription credit token" loading="lazy" />
    </div>
  );
};

export default function CssBackgroundGroup({
  value,
  coins,
  positions,
}: BackgroundCoinHolderProps) {
  const isMobile = useMediaQuery('(max-width: 640px)') ?? false;

  return (
    <>
      {positions.map((position, i) => (
        <MovingCoin
          key={i}
          {...position}
          coin={coins[position.coinIndex]}
          value={value}
          isMobile={isMobile}
        />
      ))}
    </>
  );
}
