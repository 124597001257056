import type {SVGAttributes} from 'react';

const PlusIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.36719 9.35681H14.9678"
      stroke="#5B5E61"
      strokeWidth="1.47114"
      strokeLinecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.66602 4.24341V14.4703"
      stroke="#5B5E61"
      strokeWidth="1.47114"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PlusIcon;
