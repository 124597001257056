import type {SVGAttributes} from 'react';

const ArrowDownIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.62207 7.27661L9.03549 11.69L13.4489 7.27661"
      stroke="#5B5E61"
      stroke-width="1.47114"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default ArrowDownIcon;
