import Typography from '@/components/base/elements/Typography/Typography';
import {useGmvRewardsAcquisitionIncentive} from '@/hooks/experiments/useGmvRewardsAcquisitionIncentive';
import {useTranslations} from '@/hooks/useTranslations';

import Tooltip from './Tooltip';
import type {GmvSetting} from './CssBackgroundGroup';

interface ThumbObjectProps {
  value: number;
  gmv: GmvSetting;
  calculatedTransform: number;
}

export default function ThumbObject({
  value,
  gmv,
  calculatedTransform,
}: ThumbObjectProps) {
  const {t} = useTranslations();
  const {formatValue} = useGmvRewardsAcquisitionIncentive();

  const reward = formatValue(value * 10000);

  return (
    <div className="absolute inset-x-6 top-3 z-10 pointer-events-none">
      <div
        className="absolute origin-center transition-transform duration-500 group-hover:duration-0"
        style={{
          transform: `translate3d(${calculatedTransform}px, 0, 0)`,
        }}
      >
        <Tooltip value={value} gmv={gmv} />
        <div
          className="-translate-x-2/4 font-medium"
          id="gmv-reward-componment-final-value"
        >
          <div className="flex items-center gap-1">
            <Typography className="text-t8 font-medium">{reward}</Typography>
            <Typography className="text-xs text-nowrap">
              {t('gmvCoinSlider.sliderValueLabel')}
            </Typography>
          </div>
        </div>
        {/* fake thumb */}
        <div className="absolute top-0 left-0 bg-[rgba(75,254,133,1)] rounded-full w-5 h-5 -translate-x-1/2 -translate-y-full pointer-events-none"></div>
      </div>
    </div>
  );
}
