import Typography from '@/components/base/elements/Typography/Typography';
import {useTranslations} from '@/hooks/useTranslations';
import {useGmvRewardsAcquisitionIncentive} from '@/hooks/experiments/useGmvRewardsAcquisitionIncentive';

import type {GmvSetting} from './CssBackgroundGroup';
import './slider.css';
import Slider from './Slider';

export default function GmvSlider({gmv}: {gmv: GmvSetting}) {
  const {t} = useTranslations();
  const {totalCap} = useGmvRewardsAcquisitionIncentive();

  return (
    <div className="container grid grid-cols-4 sm:grid-cols-8 md:grid-cols-12 ">
      <div className="col-span-4 xs:col-span-4 sm:col-span-8 md:col-span-10 lg:col-span-8 xl:col-span-8 xs:col-start-1 sm:col-start-1 md:col-start-2 lg:col-start-3 xl:col-start-3 rounded-3xl bg-[radial-gradient(71.54%_71.54%_at_50%_50%,#ffffff_0%,#e3fbe8_38.17%,#cefaf2_100%)]">
        <div className="p-4 md:p-8 m-6 mt-20 md:m-24 rounded-xl max-w-3xl [border-image:linear-gradient(91.22deg,rgba(255,255,255,0.48)_0%,rgba(255,255,255,0.18)_99.88%)] shadow-[0_4px_60px_0_rgba(47,47,47,0.15)]">
          <div className="group mt-10 flex items-center">
            <div className="text-t5 text-nowrap">
              {t('gmvCoinSlider.sliderLeftLabel')}
            </div>
            <Slider gmv={gmv} />
            <div className="text-t5 text-nowrap">
              {t('gmvCoinSlider.sliderRightLabel')}
            </div>
          </div>
          <Typography className="text-body-sm text-center mt-14" as="p">
            {t('gmvCoinSlider.description', {gmvCredits: totalCap})}
          </Typography>
        </div>
      </div>
    </div>
  );
}
