import {EXP_FREETRIAL_VIDEO_EXP} from '@/experiments';
import {useExperiment} from '@/hooks/useExperiment';

export enum VIDEO_TYPE_EXP {
  Control = 'control',
  Treatment = 'treatment',
  Treatment_2 = 'treatment_2',
}

export const useFreeTrialVideoSection = () => {
  const expValue = useExperiment(EXP_FREETRIAL_VIDEO_EXP) as
    | VIDEO_TYPE_EXP
    | undefined;

  return expValue ?? VIDEO_TYPE_EXP.Control;
};
