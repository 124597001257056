import type {ImageProps} from '@/components/base/elements/Image/Image';
import {EXP_HERO_IMAGE_TYPE} from '@/hooks/experiments/useFreeTrialAtfHeroImage';
import WistiaVideoPlayer from '@/components/shared/WistiaVideoPlayer/WistiaVideoPlayer';
import type {WistiaVideoPlayerProps} from '@/components/shared/WistiaVideoPlayer/types';
import AutoResponsiveImage from '@/components/base/elements/AutoResponsiveImage/AutoResponsiveImage';

import {ProductCard} from './ProductCard';
import {NavigationHelper} from './NavigationHelper';
import {PaymentsCard} from './PaymentsCard';

type VideoSectionProps = {
  experiment?: EXP_HERO_IMAGE_TYPE;
  videos: {[key: string]: WistiaVideoPlayerProps};
  images: {[key: string]: ImageProps};
};

export const HeroImageSection = ({
  experiment,
  videos,
  images,
}: VideoSectionProps) => {
  const {mobile, desktop} = videos;
  const {leftImage, middleImage, rightImage} = images;

  return (
    <>
      {experiment === EXP_HERO_IMAGE_TYPE.Treatment && (
        <div className="container flex justify-center">
          <WistiaVideoPlayer
            {...mobile}
            autoPlay={false}
            classNames={{
              wrapper:
                'block sm:hidden h-[500px] w-[350px] rounded-xl overflow-hidden [&_.w-bottom-bar-lower>div:first-child>div]:!bg-shade-70',
            }}
          />
          <WistiaVideoPlayer
            {...desktop}
            autoPlay={false}
            aspectRatio="16/9"
            classNames={{
              wrapper:
                'hidden sm:block rounded-xl overflow-hidden [&_.w-bottom-bar-lower>div:first-child>div]:!bg-shade-70',
            }}
          />
        </div>
      )}

      {experiment === EXP_HERO_IMAGE_TYPE.Treatment_2 && (
        <div className="py-4 md:py-6 flex justify-center items-center">
          <div className="flex justify-center items-center relative gap-x-2 md:gap-x-5 max-w-[900px]">
            <div className="flex w-full justify-end relative mb-6 sm:mb-0">
              <AutoResponsiveImage
                className="min-w-[123px] w-[123px] sm:w-full sm:scale-90 md:scale-100 md:w-[254px] min-h-[163px] h-[163px] md:h-[350px] rounded-xl object-cover"
                {...leftImage}
              />
              <NavigationHelper />
            </div>

            <div className="w-full">
              <ProductCard middleImage={middleImage} />
            </div>

            <div className="w-full justify-self-start relative mt-6 sm:mt-0">
              <AutoResponsiveImage
                className="min-w-[123px] w-[123px] sm:w-full sm:scale-90 md:scale-100 md:w-[254px] min-h-[163px] h-[163px] md:h-[350px] rounded-xl object-cover"
                {...rightImage}
              />
              <PaymentsCard />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
