import cn from 'classnames';
import {useLoaderData} from '@remix-run/react';

import {useTranslations} from '@/components/shared/Page/I18N';
import type {loader} from '@/pages/shopify.com/($locale)/free-trial/server/loader.server';

import {VIDEO_TYPE_EXP} from '../hooks/useFreeTrialVideoSection';

type VideoSectionProps = {
  videoType: VIDEO_TYPE_EXP;
};

export const VideoSection = ({videoType}: VideoSectionProps) => {
  const {site} = useTranslations();
  const {settings} = useLoaderData<typeof loader>();

  const videos = settings.videos;
  return (
    <div
      className={cn('container flex justify-center', {
        'mb-16': videoType === VIDEO_TYPE_EXP.Treatment_2,
      })}
    >
      <video
        className="rounded-xl"
        preload="metadata"
        poster={videos[site.locale]?.thumbnailSrc || videos?.en?.thumbnailSrc}
        controls
      >
        <source
          src={videos[site?.locale]?.src || videos?.en?.src}
          type="video/mp4"
        />
      </video>
    </div>
  );
};
