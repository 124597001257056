import {useMemo, useState} from 'react';
import {cva} from 'class-variance-authority';
import {useLoaderData} from '@remix-run/react';
import type {ActionFunctionArgs} from '@remix-run/server-runtime';
import {json} from '@remix-run/server-runtime';

import {useSiteData} from '@/hooks/useSiteData';
import {twMerge} from '@/stylesheets/twMerge';
import {useShowHeaderPdfDownload} from '@/components/brochureV2/hooks/useShowHeaderPdfDownload';
import {parseMarkdown} from '@/utils/markdown';
import type {Handle} from '@/types';
import PageLayout from '@/components/shared/Page/PageLayout';
import Footer from '@/components/brochureV2/section/Footer/Footer';
import {ColorSchemeEnum, IconEnum} from '@/enums';
import Image from '@/components/base/elements/Image/Image';
import FAQ from '@/components/base/modules/Faq/Faq';
import ConversionForm from '@/components/pages/free-trial/Conversion/ConversionForm';
import CardGrid from '@/components/base/layouts/CardGrid/CardGrid';
import EmailForm from '@/components/base/modules/EmailForm/EmailForm';
import {blogPath} from '@/hooks/navigation/utils';
import {useTranslations} from '@/components/shared/Page/I18N';
import {useSharedTranslations} from '@/hooks/useTranslations';
import Section from '@/components/base/layouts/Section/Section';
import Typography from '@/components/base/elements/Typography/Typography';
import HeadingGroup from '@/components/base/modules/HeadingGroup/HeadingGroup';
import SectionHeader from '@/components/base/sections/SectionHeader/SectionHeader';
import useSignup from '@/hooks/useSignup';
import Card from '@/components/base/modules/Card/Card';
import ButtonGroup from '@/components/base/modules/ButtonGroup/ButtonGroup';
import Icon from '@/components/base/elements/Icon/Icon';
import {useComponentPosition} from '@/hooks/useComponentPosition';
import ResponsiveShopifyLogo from '@/pages/shopify.com/($locale)/free-trial/ResponsiveShopifyLogo';
import IncentivesPromoBanner from '@/components/shared/IncentivesPromoBanner/IncentivesPromoBanner';
import MinimalFooter from '@/components/shared/MinimalFooter/MinimalFooter';
import {useIntlAcquisitionIncentive} from '@/hooks/experiments/useIntlAcquisitionIncentive';
import {useFreeTrialOutcomesRedesign} from '@/hooks/experiments/useFreeTrialOutcomesRedesign';
import useDataLayer from '@/hooks/useDataLayer';
import {DynamicHeroImage} from '@/pages/shopify.com/($locale)/free-trial/components/DynamicHeroImage';
import {useSimplifiedHeaderMessaging} from '@/hooks/experiments/useSimplifiedHeaderMessaging';
import {useGmvRewardsAcquisitionIncentive} from '@/hooks/experiments/useGmvRewardsAcquisitionIncentive';
import {
  EXP_HERO_IMAGE_TYPE,
  useFreeTrialAtfHeroImage,
} from '@/hooks/experiments/useFreeTrialAtfHeroImage';
import RequestDemoModal from '@/components/pages/shared/RequestDemoModal/RequestDemoModal';
import {requestDemoFormValidator} from '@/components/pages/shared/RequestDemoModal/RequestDemoForm';
import {submitLeadForm} from '@/components/shared/BaseLeadForm/server/submitLeadForm.server';
import {useAddingSalesCtas} from '@/hooks/experiments/useAddingSalesCtas';

import InternationalAcquisitionApps from './components/InternationalAcquisitionApps';
import DefaultLogoGroupSection from './components/DefaultLogoGroupSection';
import {useFreeTrialTestimonialsSection} from './hooks/useFreeTrialTestimonialsSection';
import {
  VIDEO_TYPE_EXP,
  useFreeTrialVideoSection,
} from './hooks/useFreeTrialVideoSection';
import type {loader} from './server/loader.server';
import {VideoSection} from './components/VideoSection';
import {HeroImageSection} from './components/HeroImageSection/HeroImageSection';
import GmvSlider from './components/GmvSlider/GmvSlider';

export const handle: Handle = {
  bodyClass: 'bg-white',
  mainClass: 'bg-[#f1fff9] overflow-x-clip',
  noIndex: true,
  noFollow: true,
  metadata: {
    pageCategory: 'Paid Landing Page',
    pageGroup: 'paid',
    pagePath: '/free-trial',
  },
  incentivePromo: true,
  driftBot({site}) {
    return ['en', 'en-CA'].includes(site.locale);
  },
};

export {loader} from './server/loader.server';

export async function action({request}: ActionFunctionArgs) {
  const fd = await request.formData();

  const [data, response] = await submitLeadForm(
    fd,
    requestDemoFormValidator,
    request,
  );

  return json(data, response);
}

// Disabling this because after adding experiment isFreeTrialOfferPath
// it is out of boundary 24 of 20
// eslint-disable-next-line complexity
export default function FreeTrialPage() {
  const {settings, paidTrial, requestDemoContent} =
    useLoaderData<typeof loader>();

  const {t: tShared} = useSharedTranslations('pages/free-trial/content');
  const {t, site, localizePath} = useTranslations();
  const data = useDataLayer();
  const {enPath} = useSiteData();
  const isDownloadDocumentCta = useShowHeaderPdfDownload(data.pageTopic);
  const {isShowImages} = useFreeTrialOutcomesRedesign();
  const {isTestmonialsSectionVariant} = useFreeTrialTestimonialsSection();
  const videoType = useFreeTrialVideoSection();
  const {signupUrl} = useSignup();
  const {
    active: internationalAcquisition,
    creditAmount: internationalAcquisitionCredit,
    guideLink,
  } = useIntlAcquisitionIncentive();
  const {isTreatment: gmvRewardsActive, totalCap} =
    useGmvRewardsAcquisitionIncentive();
  const heroImageExpValue = useFreeTrialAtfHeroImage();

  const acquisitionMinimizedDisclaimer =
    internationalAcquisition && ['de', 'jp'].includes(site?.pathPrefix || '');
  const hasSimplifiedHeader = useSimplifiedHeaderMessaging();

  const {headingKey, subheadKey} = useMemo(() => {
    const topConversionKey = internationalAcquisition
      ? 'internationalAcquisition.conversion'
      : gmvRewardsActive
        ? 'gmvConversion'
        : 'conversion1';

    const treatmentPrefix =
      hasSimplifiedHeader && topConversionKey === 'conversion1'
        ? 'treatment.'
        : '';

    return {
      headingKey: `${topConversionKey}.${treatmentPrefix}heading`,
      subheadKey: `${topConversionKey}.${treatmentPrefix}subhead`,
    };
  }, [hasSimplifiedHeader, internationalAcquisition, gmvRewardsActive]);

  const localesForHomepageUrl = [
    'en',
    'en-AU',
    'en-CA',
    'en-GB',
    'en-HK',
    'en-ID',
    'en-IE',
    'en-IN',
    'en-MY',
    'en-NG',
    'en-NO',
    'en-NZ',
    'en-PH',
    'en-SG',
    'en-ZA',
  ];

  const buttons = [
    {href: signupUrl},
    {
      href: localesForHomepageUrl.includes(site.locale)
        ? localizePath('/')
        : blogPath(
            '/?utm_source=free-trial&utm_medium=top-nav-banner&utm_campaign=blog-homepage',
            site,
          ),
    },
  ];

  const headerButtons = tShared('headerLight.buttons').map(
    (button: any, idx: number) => ({
      ...button,
      ...buttons[idx],
    }),
  );

  if (isDownloadDocumentCta) {
    headerButtons.pop();
    headerButtons.push({
      text: t('global:globalNav.headerPdfCta'),
      href: t('global:globalNav.headerPdfCtaLink'),
      target: '_blank',
    });
  } else if (!site.features.includes('blog')) {
    headerButtons.pop(); // remove button to /blog
  }

  const images = settings.images;
  const {bottom: scrollingHeaderTopStart, scrollPosition} =
    useComponentPosition('[data-partial-name="shopifyLogoTitle"]');

  const isScrolledTo = scrollPosition > scrollingHeaderTopStart;

  const getFaqQuestions = () => {
    const faq = tShared(
      internationalAcquisition
        ? 'internationalAcquisition.faq.questions'
        : 'faq.questions',
      {
        internationalAcquisitionCredit,
      },
    );
    return faq.map((item: any, index: number) => {
      return {
        heading: parseMarkdown(item.question),
        content: parseMarkdown(item.answer),
        id: `faq_${index}`,
      };
    });
  };

  const styles = cva(
    'top-0 left-0 w-full py-2 flex-col md:flex-row gap-x-xl gap-y-4 md:gap-y-0 justify-center items-center',
    {
      variants: {
        isScrolledTo: {
          true: 'fixed z-20 bg-white flex',
          false: 'hidden',
        },
      },
    },
  );

  const [isClosed, setIsClosed] = useState(false);
  const emailFormButtonText = t(
    internationalAcquisition
      ? 'internationalAcquisition.buttonText'
      : 'global:signup.buttonText',
    {
      context: site.pathPrefix,
    },
  );

  const emailFormWidth =
    emailFormButtonText.length + t('global:signup.placeholder').length > 46
      ? 'max-w-lg'
      : 'max-w-md';

  const renderHeroSection = () => {
    if (
      heroImageExpValue &&
      heroImageExpValue !== EXP_HERO_IMAGE_TYPE.Control
    ) {
      return (
        <HeroImageSection
          experiment={heroImageExpValue}
          videos={settings.videos.aftHeroImageExp}
          images={settings.images.aftHeroImageExp}
        />
      );
    }

    if (videoType === VIDEO_TYPE_EXP.Treatment) {
      return <VideoSection videoType={videoType} />;
    }

    return <DynamicHeroImage />;
  };

  const isGmvRewardsActive =
    gmvRewardsActive &&
    heroImageExpValue === EXP_HERO_IMAGE_TYPE.Control &&
    videoType === VIDEO_TYPE_EXP.Control;

  const emailForm = (
    <>
      <div className={`${emailFormWidth} mx-auto px-4 pt-6`}>
        <EmailForm
          button={{
            children: emailFormButtonText,
          }}
        />
      </div>
      <Typography
        size="body-sm"
        className="max-w-xl mx-auto text-center mt-md px-2"
        as="p"
      >
        {!acquisitionMinimizedDisclaimer
          ? t('global:signup.disclaimerHtml')
          : t('global:signup.signupPaidTrialPromo.disclaimer')}
      </Typography>
    </>
  );

  const {isAddingSalesCtasExperiment} = useAddingSalesCtas();

  const salesCta = (
    <>
      <ButtonGroup
        buttons={[
          {href: signupUrl, text: t('global:signup.buttonText')},
          {href: '#request-demo', text: requestDemoContent.salesCtaText},
        ]}
        center
        className="my-lg"
        disclaimerHtml={t('global:signup.mobileDisclaimerHtml')}
      />
      <RequestDemoModal
        content={requestDemoContent.modal}
        form={requestDemoContent.form}
        formSuccess={requestDemoContent.formSuccess}
        directMarketingCampaignId="7018V000001VKBHQA4"
      />
    </>
  );

  const isFreeTrialOfferPath = enPath?.startsWith('/free-trial-offer');

  const footer = isFreeTrialOfferPath ? (
    <Footer colorScheme={ColorSchemeEnum.Dark} />
  ) : (
    <MinimalFooter />
  );

  const header = isFreeTrialOfferPath ? undefined : () => null;

  return (
    <PageLayout header={header} footer={footer}>
      {!isClosed && !isFreeTrialOfferPath && (
        <div className={styles({isScrolledTo})}>
          <HeadingGroup
            size="t7"
            headingHtml={tShared('headerLight.subhead')}
            center
          />
          <ButtonGroup buttons={headerButtons} size="small" />
          <button
            type="button"
            onClick={() => setIsClosed(true)}
            className="absolute top-5 right-3 md:top-7 hover:opacity-70 transition-opacity flex items-center justify-center -translate-y-1/2"
            aria-label={t('global:ariaLabels.close')}
          >
            <Icon icon={IconEnum.Close} size={25} />
          </button>
        </div>
      )}

      <Section
        className={twMerge(
          cva('bg-color-[#F1FFF9] bg-free-trial-hero pt-4 gap-y-xl', {
            variants: {
              isIndexable: {
                true: 'pt-24 sm:pt-28 pb-[48px]',
                false: '',
              },
            },
          })({isIndexable: isFreeTrialOfferPath}),
        )}
        bottomSpacing="xl"
        componentName="hero"
      >
        {!isFreeTrialOfferPath && <ResponsiveShopifyLogo />}
        <div>
          <IncentivesPromoBanner mode="dark" align="center" className="pb-4" />
          <SectionHeader
            center
            headingHtml={tShared(headingKey, {
              context: site.pathPrefix,
              gmvCredits: totalCap,
            })}
            subheadHtml={tShared(subheadKey, {
              count: paidTrial.length,
              internationalAcquisitionCredit,
              context: site.pathPrefix,
            })}
            className={paidTrial.length === 1 ? 'text-pretty' : ''}
            headingAs="h1"
          />
          {isAddingSalesCtasExperiment ? salesCta : emailForm}
        </div>
      </Section>

      {!internationalAcquisition ? (
        <Section className="bg-color-[#F1FFF9] pt-0" bottomSpacing="2xl">
          {isGmvRewardsActive ? (
            <GmvSlider gmv={settings.gmv} />
          ) : (
            renderHeroSection()
          )}

          <CardGrid withContainer extraVerticalSpacing numberOfColumns={3}>
            {tShared('outcomes.blocks').map((block: any, idx: number) => (
              <Card
                key={idx}
                size="small"
                headingGroup={{
                  headingHtml: block.heading,
                  subheadHtml: block.contentHtml,
                }}
                image={{
                  alt: block.icon.alt,
                  src: isShowImages
                    ? images.outcomesExp.blocks.en[idx]
                    : images.outcomes.blocks.en[idx],
                }}
              />
            ))}
          </CardGrid>
        </Section>
      ) : (
        <Section
          className="bg-color-[#F1FFF9] pt-0 gap-y-xl"
          bottomSpacing="2xl"
        >
          <Image
            className="mx-auto w-full md:w-3/4 xl:w-1/3"
            alt={t('internationalAcquisition.image.hero.alt')}
            src={images.internationalAcquisition.hero[site?.pathPrefix || 'en']}
          />

          <InternationalAcquisitionApps
            i18n={tShared}
            internationalAcquisitionCredit={internationalAcquisitionCredit}
            pathPrefix={site?.pathPrefix}
            heroSrc={images.internationalAcquisition.apps}
            alt={t('internationalAcquisition.image.apps.alt')}
            link={guideLink}
          />
        </Section>
      )}
      {videoType === VIDEO_TYPE_EXP.Treatment_2 && (
        <VideoSection videoType={videoType} />
      )}
      <DefaultLogoGroupSection
        testimonials={t('testimonialsSection.testimonialsItems')}
        images={images?.testimonials?.en}
        internationalAcquisition={internationalAcquisition}
        pathPrefix={site.pathPrefix || ''}
      />
      {!internationalAcquisition ? (
        <ConversionForm
          buttons={[]}
          headingHtml={tShared('conversion3.heading')}
          subheadHtml={tShared('conversion3.subhead', {
            count: paidTrial.length,
          })}
          emailFormButtonText={emailFormButtonText}
          emailFormWidth={emailFormWidth}
          isBadgesAvailable={isTestmonialsSectionVariant}
        />
      ) : (
        <ConversionForm
          buttons={[]}
          headingHtml={tShared('internationalAcquisition.conversion2.heading')}
          subheadHtml={tShared('internationalAcquisition.conversion2.subhead', {
            internationalAcquisitionCredit,
          })}
          emailFormButtonText={emailFormButtonText}
          emailFormWidth={emailFormWidth}
          isBadgesAvailable={isTestmonialsSectionVariant}
        />
      )}

      <Section
        topSpacing="4xl"
        bottomSpacing="2xl"
        className="bg-color-[#F1FFF9]"
      >
        <SectionHeader headingHtml={tShared('faq.heading')} />

        <FAQ accordionItems={getFaqQuestions()} />
      </Section>
    </PageLayout>
  );
}
