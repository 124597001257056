import {useTranslations} from '@/hooks/useTranslations';
import Typography from '@/components/base/elements/Typography/Typography';

import SuccessCheckIcon from './icons/SuccessCheckIcon';

export const PaymentsCard = () => {
  const {t} = useTranslations();
  return (
    <div className="rounded-[10px] py-[5px] sm:py-[13px] absolute w-[82px] sm:w-[210px] flex justify-center flex-col items-center max-w-[82px] sm:scale-90 lg:scale-100 sm:max-w-[210px] -top-9 left-4 sm:-top-20 lg:top-36 lg:left-48 bg-[linear-gradient(206deg,_#0D0D0D_41.08%,_rgba(13,_13,_13,_0.00)_312.82%)] shadow-[0px_2.452px_18.388px_3.065px_rgba(0,_0,_0,_0.10)]">
      <div className="h-[16px] w-[16px] sm:w-[42px] sm:h-[42px] bg-[#359977] rounded-full flex justify-center items-center mb-[5px] sm:mb-[13px]">
        <SuccessCheckIcon className="w-[4px] h-[4px] sm:w-[21px] sm:h-[21px] " />
      </div>
      <Typography className="font-inter text-[6px] sm:text-base text-white font-semibold mb-[2px] sm:mb-[7px]">
        {t('experiment.payments.status')}
      </Typography>
      <Typography className="font-inter text-[4px] sm:text-xs text-[#989FA4]">
        {t('experiment.payments.value')}
      </Typography>
    </div>
  );
};
