import type {SVGAttributes} from 'react';

const SuccessCheckIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.66825 18.1047L1.08592 11.583C0.572272 11.0651 0.572272 10.2253 1.08592 9.70739C1.59956 9.18945 2.43234 9.18945 2.94599 9.70739L8.59319 15.2643L19.4944 4.40261C20.008 3.88468 20.8408 3.88468 21.3545 4.40261C21.8681 4.92055 21.8681 5.7603 21.3545 6.27823L9.51221 18.0987C9.00211 18.6076 8.18164 18.6103 7.66825 18.1047Z"
      fill="white"
    />
  </svg>
);

export default SuccessCheckIcon;
