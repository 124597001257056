import {twMerge} from '@/stylesheets/twMerge';

interface FastScrollProps {
  options: string[];
  value: number;

  container?: string;
  className?: string;
}

const TRANSLATE_START = 49.5;

export default function FastScroll({
  options,
  value,
  container,
  className,
}: FastScrollProps) {
  const yTranslate = TRANSLATE_START - (100 / options.length) * value;

  return (
    <div
      className={twMerge(
        'flex flex-col h-7 justify-center overflow-hidden',
        container,
      )}
    >
      <div
        className="flex flex-col spinner transition-transform motion-reduce:transition-none duration-200 ease-linear"
        style={{
          transform: `translate3d(0px, ${yTranslate}%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
        }}
      >
        {options.map((option) => (
          <div key={option} className={twMerge('text-xs leading-5', className)}>
            {option}
          </div>
        ))}
      </div>
    </div>
  );
}
