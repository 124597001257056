import {EXP_SIMPLIFIED_HEADER_MESSAGING} from '@/experiments';

import {useExperiment} from '../useExperiment';
import {useSiteData} from '../useSiteData';

export const useSimplifiedHeaderMessaging = () => {
  const expValue = useExperiment(EXP_SIMPLIFIED_HEADER_MESSAGING);
  const {site} = useSiteData();
  return site.pathPrefix === 'jp' && expValue === 'treatment';
};
