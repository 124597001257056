import LogoGroup from '@/components/base/modules/LogoGroup/LogoGroup';
import {useSharedTranslations, useTranslations} from '@/hooks/useTranslations';
import Section from '@/components/base/layouts/Section/Section';
import SectionHeader from '@/components/base/sections/SectionHeader/SectionHeader';
import Testimonials from '@/components/base/sections/Testimonial/Testimonials';
import {useFreeTrialTestimonialsSection} from '@/pages/shopify.com/($locale)/free-trial/hooks/useFreeTrialTestimonialsSection';
import type {TestimonialsProps} from '@/components/base/sections/Testimonial/types';

type DefaultLogoGroupSectionProps = {
  internationalAcquisition: boolean;
  pathPrefix: string;
  images: {
    image: string;
  }[];
  testimonials: TestimonialsProps[];
};

export default function DefaultLogoGroupSection({
  internationalAcquisition,
  pathPrefix,
  images,
  testimonials,
}: DefaultLogoGroupSectionProps) {
  const {t: tShared} = useSharedTranslations('pages/free-trial/content');
  const {t: tMerchantSettings} = useSharedTranslations('merchants_settings');
  const {t} = useTranslations();
  const {isTestmonialsSectionVariant} = useFreeTrialTestimonialsSection();

  if (isTestmonialsSectionVariant && testimonials.length) {
    return (
      <Section className="bg-pistachio-10" topSpacing="2xl" bottomSpacing="4xl">
        <SectionHeader
          headingHtml={t('testimonialsSection.testimonialsHeader')}
        />
        <Testimonials
          testimonials={testimonials?.map((testimonial: any, idx: number) => ({
            ...testimonial,
            image: {
              alt: testimonial?.image?.alt,
              src: images[idx]?.image,
            },
          }))}
        />
      </Section>
    );
  }

  return (
    <>
      <LogoGroup
        className="border-b-2"
        logos={tMerchantSettings('defaultLogoGroup.logos', {
          context: pathPrefix,
        })}
      />
      <Section
        topSpacing="2xl"
        bottomSpacing="4xl"
        className="bg-color-[#F1FFF9]"
      >
        {!internationalAcquisition ? (
          <SectionHeader
            center
            headingHtml={tShared('conversion2.heading')}
            subheadHtml={tShared('conversion2.subhead')}
          />
        ) : (
          <SectionHeader
            center
            headingHtml={tShared(
              'internationalAcquisition.testimonial.heading',
              {
                context: pathPrefix,
              },
            )}
            subheadHtml={tShared(
              'internationalAcquisition.testimonial.subhead',
              {
                context: pathPrefix,
              },
            )}
          />
        )}
      </Section>
    </>
  );
}
