import type {SVGAttributes} from 'react';

const MinusIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.14258 5.37207H7.95346"
      stroke="#5B5E61"
      strokeWidth="0.806427"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MinusIcon;
