import {EXP_HERO_IMAGE_EXP} from '@/experiments';
import {useExperiment} from '@/hooks/useExperiment';
import {useSiteData} from '@/hooks/useSiteData';

export enum EXP_HERO_IMAGE_TYPE {
  Control = 'control',
  Treatment = 'treatment',
  Treatment_2 = 'treatment_2',
}

const ALLOWED_LOCALES_FREE_TRIAL = ['en', 'en-GB', 'en-AU', 'en-CA', 'en-IE'];

export const useFreeTrialAtfHeroImage = () => {
  const expValue = useExperiment(EXP_HERO_IMAGE_EXP) as
    | EXP_HERO_IMAGE_TYPE
    | undefined;
  const {site} = useSiteData();

  if (!ALLOWED_LOCALES_FREE_TRIAL.includes(site.locale)) {
    return;
  }

  return expValue ?? EXP_HERO_IMAGE_TYPE.Control;
};
