import Typography from '@/components/base/elements/Typography/Typography';
import Image from '@/components/base/elements/Image/Image';
import Button from '@/components/base/elements/Button/Button';
import type {HydratedT} from '@/components/shared/Page/I18N';
import {useSiteData} from '@/hooks/useSiteData';

interface InternationalAcquisitionAppsProps {
  i18n: HydratedT;
  internationalAcquisitionCredit: string;
  pathPrefix: string | undefined;
  heroSrc: {
    mobile: {
      [key: string]: string;
    };
    desktop: {
      [key: string]: string;
    };
  };
  alt: string;
  link: string;
}

export default function InternationalAcquisitionApps({
  i18n,
  internationalAcquisitionCredit,
  pathPrefix = 'en',
  heroSrc,
  alt,
  link,
}: InternationalAcquisitionAppsProps) {
  const {site} = useSiteData();

  return (
    <div className="max-w-7xl mx-auto text-center space-y-4">
      <div className="px-4">
        <Typography size="t2" className="">
          {i18n('internationalAcquisition.appConversion.title', {
            context: pathPrefix,
            internationalAcquisitionCredit,
          })}
        </Typography>
        <Typography size="p" className="mt-1">
          {i18n('internationalAcquisition.appConversion.subhead', {
            context: pathPrefix,
            guideLink: `https://apps.shopify.com?locale=${site?.languageCode}`,
          })}
        </Typography>
      </div>

      <div>
        <Image
          src={heroSrc.mobile[pathPrefix]}
          className="mx-auto w-full md:hidden"
          alt={alt}
        />
        <Image
          src={heroSrc.desktop[pathPrefix]}
          className="mx-auto w-5/6 hidden md:block"
          alt={alt}
        />
      </div>

      <Button className="" href={link} intent="primary" target="_blank">
        {i18n('internationalAcquisition.appConversion.button', {
          context: pathPrefix,
        })}
      </Button>
    </div>
  );
}
