import type {ImageProps} from '@/components/base/elements/Image/Image';
import Image from '@/components/base/elements/Image/Image';
import {useTranslations} from '@/hooks/useTranslations';
import Typography from '@/components/base/elements/Typography/Typography';

import ForestKnitSweaterIcon from './icons/ForestKnitSweaterIcon';
import PlusIcon from './icons/PlusIcon';
import MinusIcon from './icons/MinusIcon';
import ArrowDownIcon from './icons/ArrowDownIcon';

export const ProductCard = ({middleImage}: {middleImage: ImageProps}) => {
  const {t} = useTranslations();

  return (
    <div className="relative min-w-[157px] sm:w-full md:max-w-[293px] p-3 sm:p-[20px] border border-gray-200 rounded-lg bg-white">
      <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#CDFEE1] rounded-lg flex items-center justify-center px-2 py-1 gap-x-[5px]">
        <div className="h-[9px] w-[9px] rounded bg-[#29845A]" />
        <Typography as="span" className="text-xs text-[#0C5132] font-semibold">
          {t('experiment.product.badge')}
        </Typography>
      </div>
      <ForestKnitSweaterIcon className="w-[137px] sm:w-full mb-[10px] sm:mb-[20px]" />
      <Image
        className="min-w-[157px] sm:w-full rounded mb-[10px] sm:mb-[20px]"
        {...middleImage}
      />
      <div className="flex justify-between items-center mb-[10px] sm:mb-[20px] gap-x-[15px]">
        <div className="p-2 border border-gray-300 rounded-md bg-white w-full text-[7px] sm:text-xs text-[#494949] flex items-center justify-between">
          <Typography className="text-[7px] sm:text-xs" as="span">
            {t('experiment.product.size')}
          </Typography>
          <ArrowDownIcon className="h-[9px] sm:h-[18px]" />
        </div>
        <div className="flex items-center justify-between bg-transparent border border-[#D5D9DE] rounded p-2 w-full text-[7px] sm:text-xs text-[#494949]">
          <MinusIcon className="h-[9px] sm:h-[18px]" />
          <Typography as="span" className="mx-2 text-[7px] sm:text-xs">
            {t('experiment.product.count')}
          </Typography>
          <PlusIcon className="h-[9px] sm:h-[18px]" />
        </div>
      </div>
      <div className="w-full bg-banana-10 text-[#877701] py-2 rounded-md font-medium flex justify-center items-center text-[8px] sm:text-[15px] shadow-[0px_4.65px_4.65px_0px_#463F071A]">
        {t('experiment.product.submit')}
      </div>
    </div>
  );
};
