import {EXP_VALUE_PROP_DESIGN} from '@/experiments';
import {useExperiment} from '@/hooks/useExperiment';
import {useSiteData} from '@/hooks/useSiteData';

export const useFreeTrialOutcomesRedesign = () => {
  const expValue = useExperiment(EXP_VALUE_PROP_DESIGN);
  const {site} = useSiteData();

  return {
    isShowImages:
      ['en-GB', 'en-AU', 'en-CA', 'en-IE'].includes(site.locale) &&
      expValue === 'treatment',
  };
};
