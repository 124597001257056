import {useState, useRef, useCallback, useEffect} from 'react';

import useIntersectionObserver from '@/hooks/useIntersectionObserver';

import ThumbObject from './ThumbObject';
import type {GmvSetting} from './CssBackgroundGroup';

const VALUE_COUNT = 100;
const THUMB_WIDTH = 20;

export default function GmvSlider({gmv}: {gmv: GmvSetting}) {
  const [inputRefWidth, setInputRefWidth] = useState(0);
  const [value, setValue] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);

  const [sliderSeen, setSliderSeen] = useState(false);

  const updateValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(parseInt(e.target.value, 10));
    },
    [setValue],
  );

  const observe = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (
        entries.some((entry: IntersectionObserverEntry) => {
          return entry.isIntersecting && !sliderSeen;
        })
      ) {
        setValue(30);
        setSliderSeen(true);
      }
    },
    [sliderSeen],
  );

  useIntersectionObserver(inputRef, observe);

  useEffect(() => {
    if (!inputRef.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentBoxSize) {
          setInputRefWidth(entry.contentRect.width);
        }
      }
    });

    resizeObserver.observe(inputRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [inputRef, setInputRefWidth]);

  const calculatedTransform =
    (inputRefWidth * value) / 100 -
    (THUMB_WIDTH * value) / 100 +
    THUMB_WIDTH / 2;

  return (
    <div className="w-full px-6 relative flex items-center">
      {/* fake track */}
      <div className="absolute h-0.5 inset-x-6 top-[1px] bg-shade-40"></div>
      <ThumbObject
        value={value}
        gmv={gmv}
        calculatedTransform={calculatedTransform}
      />
      <input
        ref={inputRef}
        type="range"
        min="0"
        max={VALUE_COUNT}
        className="input-background reset-input-range w-full relative z-10 h-1 rounded-sm touch-none transition-[background-size] duration-500 group-hover:duration-0 cursor-pointer"
        style={{backgroundSize: `${(value * 100) / VALUE_COUNT}% 100%`}}
        onInput={updateValue}
        value={value}
        // Accessibility
        aria-describedby="gmv-reward-componment-final-value"
        aria-live="polite"
        // Analytics
        data-event-label="gmv-reward-slider"
        data-component-name="gmv-reward-slider"
      />
    </div>
  );
}
