import {ChevronRightIcon} from '@shopify/polaris-icons';

import {useTranslations} from '@/hooks/useTranslations';
import Typography from '@/components/base/elements/Typography/Typography';

import VisualIcon from './icons/VisualIcon';
import RoundedPlusIcon from './icons/RoundedPlusIcon';

export const NavigationHelper = () => {
  const {t} = useTranslations();

  return (
    <div className="bg-white shadow-[3.24px_4.31px_15.1px_0px_#00000026] absolute max-w-[71px] sm:scale-90 lg:scale-100 sm:max-w-[210px] -bottom-9 right-4 sm:-bottom-20 lg:top-36 lg:right-48 rounded sm:rounded-xl w-full h-fit">
      <div className="border-b border-[#EBEBEB] p-[5px] sm:p-[15px]">
        <Typography className="font-semibold text-[5px] sm:text-xs font-inter">
          {t('experiment.navigation.title')}
        </Typography>
      </div>
      <div>
        <div className="py-[3px] px-[5px] sm:px-[20px] sm:py-[10px]">
          <Typography className="font-semibold text-[5px] sm:text-xs font-inter text-[#616161]">
            {t('experiment.navigation.header')}
          </Typography>
        </div>
        <div className="px-[5px] sm:px-[15px] sm:py-[10px] flex items-center">
          <ChevronRightIcon className="fill-[#8A8A8A] h-[5px] sm:h-[18px] w-[5px] sm:w-[18px]" />
          <VisualIcon className="fill-[#4A4A4A] h-[5px] md:h-[18px] w-[5px] md:w-[18px]" />
          <Typography className="font-semibold text-[5px] sm:text-xs font-inter text-black ml-[5px]">
            {t('experiment.navigation.header')}
          </Typography>
        </div>
        <div className="px-[5px] py-[5px] sm:px-[32px] sm:pt-[10px] sm:pb-[20px] flex items-center">
          <RoundedPlusIcon className="fill-[#005BD3] h-[5px] sm:h-[18px] w-[5px] sm:w-[18px]" />
          <Typography className="font-semibold text-[5px] sm:text-xs font-inter text-[#005BD3] ml-[5px]">
            {t('experiment.navigation.submit')}
          </Typography>
        </div>
      </div>
    </div>
  );
};
