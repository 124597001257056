import {useMemo} from 'react';

import {useTranslations} from '@/hooks/useTranslations';
import Typography from '@/components/base/elements/Typography/Typography';
import {useGmvRewardsAcquisitionIncentive} from '@/hooks/experiments/useGmvRewardsAcquisitionIncentive';

import CssBackgroundGroup, {type GmvSetting} from './CssBackgroundGroup';
import FastScroll from './FastScroll';

interface TooltipProps {
  value: number;
  gmv: GmvSetting;
}

const MAX_PAYOUT = 10000;
const TICKER_INTERVAL = 100;

export default function Tooltip({value, gmv}: TooltipProps) {
  const {t} = useTranslations();
  const {formatValue} = useGmvRewardsAcquisitionIncentive();

  const options = useMemo(() => {
    const strings: string[] = [];
    for (let i = 0; i <= MAX_PAYOUT; i += TICKER_INTERVAL) {
      strings.push(formatValue(i));
    }
    return strings;
  }, [formatValue]);

  return (
    <div className="absolute top-0 left-0 -translate-y-[130%] -translate-x-[50%] md:-translate-x-[20%]">
      <div className="absolute top-1/3 left-1/2 -translate-x-[50%] -translate-y-[50%]">
        <CssBackgroundGroup
          value={value}
          positions={gmv.positions}
          coins={gmv.coins}
        />
      </div>
      <div className="pl-3 pr-5 py-6 rounded-xl backdrop-blur-sm w-full bg-white">
        <div className="flex flex-col mr-6">
          <Typography className="ps-1 text-xs uppercase font-normal" as="p">
            {t('gmvCoinSlider.tooltip.heading')}
          </Typography>
          <div className="ps-1 faded h-7">
            <FastScroll
              options={options}
              value={value}
              className="text-black text-xl font-medium"
            />
          </div>
          <Typography
            className="ps-1 text-xs font-normal uppercase whitespace-nowrap"
            as="p"
          >
            {t('gmvCoinSlider.tooltip.subheading')}
          </Typography>
        </div>
        <div className="absolute right-0 top-1/2 w-20 h-20 translate-x-[45%] -translate-y-1/2">
          <img
            src="https://cdn.shopify.com/b/shopify-brochure2-assets/a2a4b341834a5916b4f977d57835c06a.png"
            alt="Subscription credit token"
          />
        </div>
      </div>
    </div>
  );
}
