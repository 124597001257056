import type {SVGAttributes} from 'react';

const RoundedPlusIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.54919 9.98548C6.54919 9.6113 6.85252 9.30796 7.2267 9.30796H9.25925V7.27541C9.25925 6.90123 9.56259 6.59789 9.93677 6.59789C10.311 6.59789 10.6143 6.90123 10.6143 7.27541V9.30796H12.6468C13.021 9.30796 13.3244 9.6113 13.3244 9.98548C13.3244 10.3597 13.021 10.663 12.6468 10.663H10.6143V12.6955C10.6143 13.0697 10.311 13.3731 9.93677 13.3731C9.56259 13.3731 9.25925 13.0697 9.25925 12.6955V10.663H7.2267C6.85252 10.663 6.54919 10.3597 6.54919 9.98548Z"
      fill="#005BD3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.93677 16.309C13.4291 16.309 16.2603 13.4778 16.2603 9.98548C16.2603 6.49311 13.4291 3.66199 9.93677 3.66199C6.4444 3.66199 3.61328 6.49311 3.61328 9.98548C3.61328 13.4778 6.4444 16.309 9.93677 16.309ZM9.93677 14.9539C12.6808 14.9539 14.9052 12.7295 14.9052 9.98548C14.9052 7.24147 12.6808 5.01702 9.93677 5.01702C7.19277 5.01702 4.96831 7.24147 4.96831 9.98548C4.96831 12.7295 7.19277 14.9539 9.93677 14.9539Z"
      fill="#005BD3"
    />
  </svg>
);

export default RoundedPlusIcon;
