import {EXP_COGNISABLY_SANGUINE_CLAMPERS} from '@/experiments';
import {useExperiment} from '@/hooks/useExperiment';

export const useFreeTrialTestimonialsSection = () => {
  const isTestmonialsSectionVariant =
    'treatment' === useExperiment(EXP_COGNISABLY_SANGUINE_CLAMPERS);
  const isLogosVariant =
    'control' === useExperiment(EXP_COGNISABLY_SANGUINE_CLAMPERS);
  return {
    isTestmonialsSectionVariant,
    isLogosVariant,
  };
};
